import React, { useCallback, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import { useForm } from 'react-hook-form';
import { RiCloseLine } from 'react-icons/ri';

import Select from 'react-select';
import Button from '../../lib/Button';
import CustomSelect from '../../lib/Select';
import { stylePrimary } from '../../lib/Select/style';

import styles from './ExportModale.module.scss';
import { getStructuresAction, postExportAction } from '../../../actions/structuresActions';
import { EXPORT_IS_LOADING } from '../../../actions/types';
import { periodsOptions, monthsArray, months } from '../../../constants/constants';

export default function ExportModale() {
  const dispatch = useDispatch();
  const { structuresList, loaders } = useSelector((state) => state.structures);
  const visualisationReducer = useSelector((state) => state.visualisation);
  const { structures, filters } = visualisationReducer;
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      structures,
    },
  });

  const form = watch();
  const options = {
    first: !form.isMonth ? periodsOptions.y0 : periodsOptions.m0,
    second: !form.isMonth ? periodsOptions.y1 : periodsOptions.m1,
  };

  const getStructures = useCallback(() => {
    getStructuresAction(dispatch);
  }, [dispatch]);

  function handleChangeStructures(value) {
    let list = [];
    const structuresToExport = form.structures?.length > 0 ? form.structures : [];
    if (!structuresToExport?.find((id) => id === value)) {
      list = [...structuresToExport, value];
    }
    if (structuresToExport?.find((id) => id === value)) {
      list = structuresToExport.filter((id) => id !== value);
    }
    if (list.length === 0) {
      return null;
    }
    return setValue('structures', list);
  }

  function handleChangeForm(obj) {
    reset({ ...form, ...obj });
  }

  function submitExport(values) {
    const data = { ...values };
    let label = 'export-';
    const selectedStructure = data?.structures?.length > 0
      ? structuresList?.find((s) => s._id === data.structures[0]) : null;

    if (selectedStructure?.name) {
      label = `${selectedStructure?.name}`;
    }
    if (data.m0 !== '') {
      label += `-${months[data.m0]}`;
    }
    if (data.y0) {
      label += `-${data.y0}`;
    }
    if (data.type === 'pdf') {
      if (data.m1 !== '') {
        label = +`-${months[data.m1]}`;
      }
      if (data.y1) {
        label += `-${data.y1}`;
      }
    }
    console.log(label);
    postExportAction(dispatch, data, label);
    dispatch({
      type: EXPORT_IS_LOADING,
      payload: true,
    });
  }

  function handleChangeCompareType(isMonth) {
    const data = { ...form, isMonth };
    if (!isMonth) {
      data.m0 = '';
      data.m1 = '';
      const year = parseFloat(data.y0) - 1;
      data.y1 = year.toString();
    } else {
      const monthIndex = new Date().getMonth();
      if (monthIndex !== -1) {
        data.m0 = monthsArray[monthIndex];
        data.m1 = monthsArray[monthIndex];
        const year = parseFloat(data.y0) - 1;
        data.y1 = year.toString();
      }
    }
    handleChangeForm(data);
  }

  useEffect(() => {
    getStructures();
  }, []);

  useEffect(() => {
    register('structures');
    register('period');
    register('type');
  }, [register]);

  useEffect(() => {
    if (!structuresList) return null;
    if (filters?.structures?.length) {
      reset({ ...filters, type: 'pdf' });
    }
    return null;
  }, [filters]);

  return (
    <div className={styles.container}>
      <h2>Export</h2>
        <form>
        <div className={styles.select}>
          {structuresList?.length > 1 && <CustomSelect
            className={'primary'}
            options={structuresList?.map((s) => ({ label: s.name, value: s._id }))}
            value={structuresList}
            placeholder={'Séléctionner un site'}
            handleChange={(value) => handleChangeStructures(value)}
            />
          }
        </div>
        <div className={styles.list}>
          {structuresList?.filter(
            (s) => form.structures?.find((id) => id === s._id),
          ).map((s) => <div
            key={s._id}
            className={styles['list-item']}
          >
            <p>{s.name}</p>
            {form.structures.length > 1
              && <button
                onClick={() => handleChangeStructures(s._id)}
                >
                <RiCloseLine size={15} />
              </button>
            }
          </div>)}
        </div>

        <div className={styles['month-toggle']}>
          <button
            type='button'
            className={form.isMonth ? styles.active : ''}
            onClick={() => handleChangeCompareType(true)}
          >
            Mois
          </button>
          <button
            type='button'
            onClick={() => handleChangeCompareType(false)}
            className={!form.isMonth ? styles.active : ''}
          >
            Année
          </button>
        </div>

        <div className={styles.select}>
          <Select
            styles={stylePrimary}
            options={options?.first}
            value={options?.first.find(
              (opt) => opt.value.y0 === form.y0
              && opt.value.m0 === form.m0,
            )}
            onChange={(opt) => handleChangeForm(opt.value)}
          />
        </div>

        {watch('type') === 'pdf'
          && <div className={styles.select}>
            <Select
              styles={stylePrimary}
              options={options?.second}
              value={options?.second.find(
                (opt) => opt.value.y1 === form.y1
                && opt.value.m1 === form.m1,
              )}
              onChange={(opt) => handleChangeForm(opt.value)}
            />
          </div>
        }

        <div className={styles.file}>
          <label>Type de fichier</label>
          <div className={styles.type}>
            <p className={`${watch('type') === 'pdf' ? styles.active : ''}`}>PDF</p>
            <label className={styles.toggle}>
              <input type="checkbox" checked={watch('type') === 'xlsx'} onChange={() => setValue('type', watch('type') === 'pdf' ? 'xlsx' : 'pdf')}/>
              <div className={styles['toggle-control']}></div>
            </label>
            <p className={`${watch('type') === 'xlsx' ? styles.active : ''}`}>EXCEL</p>
          </div>
        </div>

         <div className={styles.buttons}>
            <Button
              handleClick={handleSubmit(submitExport)}
              isLoading={loaders.export}
              primary
              >
              Exporter
            </Button>
          </div>
        </form>
    </div>
  );
}
