import React, { useState, useRef, useEffect } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { calcPercentage, numberWithSpaces } from '../../../utils/utils';
import GerRow from '../GerRow/GerRow';
import styles from './Ger.module.scss';

export default function Ger({
  data,
}) {
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const contentRef = useRef(null);

  const { provisional } = data.total;
  const { reel } = data.total;
  const percentageValue = provisional <= reel
    ? calcPercentage(provisional, reel) : calcPercentage(reel, provisional);
  const expenses = data?.expenses?.sort((a, b) => new Date(a.date) - new Date(b.date));

  const [list, setList] = useState();

  useEffect(() => {
    if (!expenses) return null;
    return setList(expenses);
  }, [expenses]);

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        <div className={styles.header}>
          <label>GER</label>
          <button
            onClick={() => setPanelIsOpen(!panelIsOpen)}
            >
            {panelIsOpen ? <RiArrowUpSLine size={28} color={'#192243'}/> : <RiArrowDownSLine size={28} color={'#192243'}/>}
          </button>
        </div>
        <div
          className={styles.panel}
          style={{
            height: panelIsOpen && list && contentRef?.current?.clientHeight > 0
              ? contentRef.current.clientHeight : 180,
          }}>
          <div ref={contentRef}>
            <div className={styles.content}>
              {list?.map((e) => <GerRow key={e._id} expense={e} />)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.total}>
        <label>Total GER</label>
        <div className={styles.value}>
          <h3>
            {numberWithSpaces(data?.total?.reel)} €
          </h3>
        </div>
          <p
              className={styles.percentage}
              style={{
                color: provisional <= reel
                  ? '#E54262'
                  : '#33C597',
              }}
            >
              {!Number.isNaN(percentageValue)
              && <>
              {provisional > reel ? '-' : '+'}
              {numberWithSpaces(percentageValue.toFixed())}
              %
              </>
              }
            </p>
          <div className={styles.provisional}>
            <label>Prévisionnel</label>
            <p>
              {numberWithSpaces(data?.total?.provisional)} €
            </p>
          </div>
      </div>
    </div>
  );
}
