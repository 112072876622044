import { getData, putData, postData } from './index';

import {
  ERROR_VISUALISATION,
  GET_QUESTIONS,
  PUT_QUESTION,
  POST_QUESTION,
  GET_SYNTHESIS,
  GET_FREQUENTATION,
  GET_CA,
  GET_FILLING_VIZ,
  GET_EXPENSE_VIZ,
  GET_CONSUMPTION_VIZ,
} from './types';

export async function getQuestionsAction(dispatch) {
  const url = '/question';
  let questions;
  await getData(ERROR_VISUALISATION, url, dispatch, true).then((response) => {
    questions = response.questions;
  });
  dispatch({
    type: GET_QUESTIONS,
    payload: questions,
  });
}

export async function putQuestionAction(dispatch, id, data) {
  const url = `/question/${id}`;
  let putQuestion;

  await putData(PUT_QUESTION, ERROR_VISUALISATION, url, dispatch, data, true).then((response) => {
    putQuestion = response.question;
  });

  dispatch({
    type: PUT_QUESTION,
    payload: putQuestion,
  });
}

export async function postQuestionAction(dispatch, obj) {
  const url = '/question';
  let question;
  await postData(POST_QUESTION, ERROR_VISUALISATION, url, dispatch, obj, true).then((response) => {
    question = response;
  });

  dispatch({
    type: POST_QUESTION,
    payload: question.question,
  });
}

export async function getSynthesisAction(dispatch, obj) {
  const url = '/viz/synthesis';
  let synthesis;
  await postData(GET_SYNTHESIS, ERROR_VISUALISATION, url, dispatch, obj, true).then((response) => {
    synthesis = response;
  });

  dispatch({
    type: GET_SYNTHESIS,
    payload: synthesis.data,
  });
}

export async function getFrequentationAction(dispatch, obj) {
  const url = '/viz/frequentation';
  let frequentation;
  await postData(
    GET_FREQUENTATION,
    ERROR_VISUALISATION,
    url,
    dispatch,
    obj,
    true,
  ).then((response) => {
    frequentation = response;
  });

  dispatch({
    type: GET_FREQUENTATION,
    payload: frequentation.data,
  });
}

export async function getCaAction(dispatch, obj) {
  const url = '/viz/ca';
  let ca;
  await postData(
    GET_CA,
    ERROR_VISUALISATION,
    url,
    dispatch,
    obj,
    true,
  ).then((response) => {
    ca = response;
  });

  dispatch({
    type: GET_CA,
    payload: ca.data,
  });
}

export async function getFillingAction(dispatch, obj) {
  const url = '/viz/filling';
  let filling;
  await postData(
    GET_FILLING_VIZ,
    ERROR_VISUALISATION,
    url,
    dispatch,
    obj,
    true,
  ).then((response) => {
    filling = response;
  });

  dispatch({
    type: GET_FILLING_VIZ,
    payload: filling.data,
  });
}

export async function getConsumptionAction(dispatch, obj) {
  const url = '/viz/consumption';
  let consumption;
  await postData(
    GET_CONSUMPTION_VIZ,
    ERROR_VISUALISATION,
    url,
    dispatch,
    obj,
    true,
  ).then((response) => {
    consumption = response;
  });
  // if (obj.period !== 'year') {
  //   const water = { label: 'Eau', unit: 'm3' };
  //   consumption.data.water.forEach((d) => {
  //     water[d.id] = d.data || 0;
  //   });
  //   consumption.data.water = [water];
  // }
  dispatch({
    type: GET_CONSUMPTION_VIZ,
    payload: consumption.data,
  });
}

export async function getExpensesVisAction(dispatch, obj) {
  const url = '/viz/expense';
  let expense;
  await postData(
    GET_EXPENSE_VIZ,
    ERROR_VISUALISATION,
    url,
    dispatch,
    obj,
    true,
  ).then((response) => {
    expense = response;
  });

  if (obj.period !== 'year' && expense.data.payrolls.length) {
    expense.data.payrolls[0].key = 'Masse salariale';
  }

  dispatch({
    type: GET_EXPENSE_VIZ,
    payload: expense.data,
  });
}
