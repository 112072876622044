import React, { useRef, useState, useEffect } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import { months } from '../../../constants/constants';

import { putConsumptionAction } from '../../../actions/formsActions';

import MonthRow from '../../partials/MonthRow/MonthRow';

import styles from './ConsumptionYear.module.scss';

export default function ConsumptionYear({
  content,
  provisionalContent,
  isProvisional,
  name,
  id,
}) {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const autoSaveTimer = useRef();
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [contentList, setContentList] = useState();
  const [form, setForm] = useState({});

  function saveWithTimer(data) {
    clearTimeout(autoSaveTimer.current);
    autoSaveTimer.current = setTimeout(() => putConsumptionAction(dispatch, id, data), 3000);
  }

  function handleChange(key, value) {
    const data = { ...form, [key]: value };
    setForm(data);
    saveWithTimer(data);
  }

  useEffect(() => {
    if (content) {
      const list = [];
      let unit = 'm3';
      if (name === 'Électricité' || name === 'Gaz') {
        unit = 'Mwh';
      }
      Object.entries(content).forEach(([key, value]) => {
        list.push({
          _id: `${id}-${key}`,
          key: `content.${key}`,
          label: months[key],
          isProvisional,
          provisional: provisionalContent && provisionalContent[key],
          unit,
          name,
          value,
        });
      });
      setForm({});
      setContentList(list);
    }
  }, [content]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>{name}</h2>
        <button
          onClick={() => setPanelIsOpen(!panelIsOpen)}
          >
          {panelIsOpen ? <RiArrowUpSLine size={28} color={'#192243'}/> : <RiArrowDownSLine size={28} color={'#192243'}/>}
        </button>
      </div>
      <div
        className={styles.panel}
        style={{
          height: panelIsOpen && contentRef?.current?.clientHeight > 0
            ? contentRef.current.clientHeight : 0,
        }}>
        <div ref={contentRef}>
          <div className={styles.content}>
          {contentList && contentList?.map((m, i) => (
            <MonthRow
              key={m._id}
              monthData={m}
              index={i}
              handleChange={(value) => handleChange(m.key, value)}
            />
          ))}
          </div>
        </div>
      </div>
    </div>
  );
}
