import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_EXPENSE_VIZ } from '../../../actions/types';
import { getExpensesVisAction } from '../../../actions/visualisationActions';
import GroupedBarChart from '../../../components/partials/Charts/GroupedBarChart/GroupedBarChart';
// import LineChartBloc from '../../../components/partials/Charts/LineChartBloc/LineChartBloc';
import Ger from '../../../components/partials/Ger/Ger';

import { colors } from '../../../constants/constants';

import styles from './Expense.module.scss';

export default function Expense() {
  const { expenses, filters } = useSelector((state) => state.visualisation);
  const dispatch = useDispatch();
  const getData = useCallback(() => {
    getExpensesVisAction(dispatch, filters);
    dispatch({
      type: GET_EXPENSE_VIZ,
      payload: null,
    });
  }, [dispatch, filters]);

  useEffect(() => {
    if (filters.structures?.length > 0) {
      getData();
    }
  }, [filters]);

  const [ger, setGer] = useState();
  const [payrolls, setPayrolls] = useState();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!expenses) return null;
    const expensesData = {
      total: {
        reel: 0,
        provisional: 0,
      },
    };
    const allExpenses = expenses.expenses;
    allExpenses.forEach((e) => {
      if (e.isProvisional) {
        expensesData.total.provisional += e.amount;
      } else {
        expensesData.total.reel += e.amount;
      }
    });
    expensesData.expenses = allExpenses.filter((e) => !e.isProvisional);
    return setGer(expensesData);
  }, [expenses?.expenses, filters.structures]);

  useEffect(() => {
    if (expenses?.payrolls) {
      const payrollsData = expenses?.payrolls;
      if (payrollsData[0]?.data) {
        const yearData = [];
        payrollsData.forEach((p) => {
          p.data.forEach((d) => {
            const index = yearData.findIndex((y) => y.month === d.x);

            if (index !== -1) {
              yearData[index][p.id] = d.y;
            } else {
              yearData.push({
                month: d.x,
                [p.id]: d.y,
              });
            }
          });
        });
        setPayrolls(yearData);
      } else {
        setPayrolls(payrollsData);
      }
    }
  }, [expenses?.payrolls]);

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      {expenses
        && <>
          <div className={styles.block}>
            {ger && <Ger data={ger}/>}
          </div>
          {payrolls && <>
            {/* {period === 'year'
              ? <div className={styles.block}>
              <LineChartBloc
                data={expenses?.payrolls || []}
                colors={colors}
                unit={'k€'}
                margin={{
                  top: 45, right: 10, bottom: 50, left: 70,
                }}
                legend
                indexBy='activity'
                keys={['Réel', 'Prévisionnel', 'Année N-1', 'Année N-2']}
                label='masse salariale'
                />
            </div>
              :  */}
              <div className={styles.block}>
                <GroupedBarChart
                  data={payrolls}
                  colors={colors}
                  unit={'€'}
                  legend
                  indexBy='month'
                  margin={{
                    top: 20, right: 10, bottom: 50, left: 70,
                  }}
                  keys={['Réel', 'Prévisionnel', 'Période de comparaison']}
                  label='masse salariale'
                  />
            </div>
            {/* } */}
            </>
          }
        </>
      }
    </div>
  );
}
