import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SET_CONFIG, POST_STRUCTURE_DATA } from '../../../actions/types';
import { postStructureDataAction } from '../../../actions/formsActions';

import FillingType from '../../../components/partials/FillingType/FillingType';

import styles from './Filling.module.scss';

export default function Filling() {
  const {
    config, data,
  } = useSelector((state) => state.structures);
  const dispatch = useDispatch();

  const [fillings, setFillings] = useState();

  const initConfig = useCallback(() => {
    dispatch({
      type: SET_CONFIG,
      payload: { ...config, type: 'filling' },
    });
    dispatch({
      type: POST_STRUCTURE_DATA,
      payload: null,
    });
  }, [dispatch]);

  const getData = useCallback(() => {
    postStructureDataAction(dispatch, config);
  }, [dispatch, config]);

  useEffect(() => {
    initConfig();
  }, []);

  useEffect(() => {
    if (config?.structure && config?.year && config?.type === 'filling') {
      getData();
      setFillings();
    }
  }, [config.year, config?.structure, config?.type, config?.isProvisional]);

  useEffect(() => {
    if (data && config?.type === 'filling') {
      const typesList = [];
      data.forEach((d) => {
        const subTypesList = data.filter((item) => item.type === d.type
        && !typesList.find((t) => t.name === d.type));
        if (d.type && subTypesList.length > 0) {
          typesList.push({
            name: d.type,
            subTypes: subTypesList,
          });
        }
      });
      setFillings(typesList);
    }
  }, [data]);

  return (
    <div className={`page-content ${styles.container}`}>
      {fillings?.map((f) => <FillingType
        key={f.name}
        name={f.name}
        subTypes={f.subTypes}
      />)}
    </div>
  );
}
