import React from 'react';
import { MdAccessibility } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { months } from '../../../constants/constants';
import { calcPercentage, numberWithSpaces } from '../../../utils/utils';

import styles from './SynthesisValue.module.scss';

export default function SynthesisValue({
  data,
  isInline,
}) {
  const { filters } = useSelector((state) => state.visualisation);

  const provisionalPercentage = useSpring({
    val: calcPercentage(data.provisional, data.reel) || 0,
    from: 0,
  });

  return (
    <div className={`${styles.container} ${isInline ? styles['is-inline'] : ''}`}>
      <label>{data.label}
        <br/>
        <span>{filters?.m0 && months[filters?.m0]} {filters?.y0}</span>
      </label>

      <div className={`${isInline ? styles.inline : ''}`}>
        <h3>
          {numberWithSpaces(data.reel || 0)} {data.unit !== 'person' ? data.unit : <MdAccessibility /> }
        </h3>

        <div className={styles.row}>
          <div className={styles.col}>
            <label>Prévisionnel</label>
            <h4>
              {numberWithSpaces(data.provisional || 0)} {data.unit !== 'person' ? data.unit : <MdAccessibility />}
            </h4>
          </div>
          <div className={styles.col}>
            {data.reel && data.reel !== 0
              ? <h4 style={{
                color: calcPercentage(data.provisional, data.reel) >= 0
                  ? '#33C597'
                  : '#E54262',
              }}>
                {calcPercentage(data.provisional, data.reel) >= 0 && '+'}
                <animated.span>
                {Number(calcPercentage(data.provisional, data.reel))
                  === calcPercentage(data.provisional, data.reel)
                  ? provisionalPercentage.val.interpolate((val) => Math.floor(val))
                  : 0
                }
                </animated.span>
                %
              </h4>
              : <h4>---%</h4>
            }
          </div>
        </div>
      </div>
        <div className={`${styles.row} ${styles.years}`}>
          <div className={styles.col}>
            <label>{filters?.m1 && months[filters?.m1]} {filters?.y1}</label>
            {data.n1
              ? <h4>
                {numberWithSpaces(data.n1)} {data.unit !== 'person' ? data.unit : <MdAccessibility />}
                {/* {calcPercentage(data.reel, data.n1) >= 0
                  ? ''
                  : '+'
                } */}
                {/* <animated.span>
                  {Number(calcPercentage(data.reel, data.n1))
                    === calcPercentage(data.reel, data.n1)
                    ? n1.val.interpolate((val) => Math.floor(val))
                    : 0
                  }
                </animated.span>% */}
              </h4>
              : <h4>---</h4>
              }
          </div>
        </div>
    </div>
  );
}
