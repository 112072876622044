import React from 'react';
import { numberWithSpaces } from '../../../../utils/utils';
import styles from './Table.module.scss';

export default function Table({
  data,
  keys,
  unit,
  indexBy,
}) {
  return (
    <div className={styles.table} >
      <table>
        <thead>
          <tr>
            <th scope="col">Type</th>
            {keys.map((k) => <th key={k} scope="col">{k}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
              <tr key={d[indexBy]}>
              <th scope="row">{d[indexBy]} {d?.unit && <span>({d?.unit})</span>}</th>
              {keys.map((k) => <td key={k} scope="col">{numberWithSpaces(d[k])} {unit}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
