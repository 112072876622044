import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getQuestionsAction, postQuestionAction } from '../../../actions/visualisationActions';

import TextArea from '../../lib/Textarea';
import Button from '../../lib/Button';
import ErrorField from '../../lib/ErrorField';
import Notif from '../Notif/Notif';
import CustomSelect from '../../lib/Select';
import styles from './Notifications.module.scss';

export default function Notifications() {
  const dispatch = useDispatch();
  const { structuresList } = useSelector((state) => state.structures);
  const { questions } = useSelector((state) => state.visualisation);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: {
      errors,
    },
  } = useForm();

  function submitQuestion(values) {
    postQuestionAction(dispatch, values);
    reset({
      question: null,
      structure: null,
    });
  }

  const getQuestions = useCallback(() => {
    getQuestionsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getQuestions();
  }, []);

  useEffect(() => {
    if (structuresList?.length === 1) {
      setValue('structure', structuresList[0]._id);
    }
  }, [structuresList]);

  return (
    <div className={styles.container}>
      <label>Notifications</label>
      <div className={styles.notifications}>
        <div className={styles.textarea}>
          {structuresList?.length > 1 && <CustomSelect
            options={structuresList?.map((s) => ({ label: s.name, value: s._id }))}
            value={null}
            placeholder={'Sélectionner un site'}
            handleChange={(value) => setValue('structure', value)}
            />
          }
          <TextArea
            name="question"
            rules={{ required: 'la question est requise' }}
            control={control}
            />
          <div className={styles.btn}>
            <Button
              handleClick={handleSubmit(submitQuestion)}
              primary
              size='xs'
              >
              Envoyer ma question
            </Button>
          </div>
        </div>
        {errors.question?.message && <ErrorField message={errors.question?.message}/>}
        {watch('question') && !watch('structure') && <ErrorField message={'Sélectionner un site'}/>}
        {questions?.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
          .reverse().map((q) => <Notif
          key={q._id}
          notif={q}
        />)}
      </div>
    </div>
  );
}
