import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SynthesisValue from '../../../components/partials/SynthesisValue/SynthesisValue';
import styles from './Ca.module.scss';
import { colors } from '../../../constants/constants';
import { getCaAction } from '../../../actions/visualisationActions';
import SelectGraph from '../../../components/partials/SelectGraph';

export default function Ca() {
  const { ca, filters } = useSelector((state) => state.visualisation);
  const dispatch = useDispatch();

  const getData = useCallback(() => {
    getCaAction(dispatch, filters);
  }, [dispatch, filters]);

  useEffect(() => {
    if (filters.structures.length > 0) {
      getData();
    }
  }, [filters]);

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      {ca
        && <>
        <div className={styles.synthesis}>
          {ca?.caData.map((d) => <SynthesisValue
            key={d.label}
            data={d}
            // period={period}
            isInline
          />)}
        </div>
        <div className={styles.block}>
          {ca?.byType
            && <SelectGraph
            data={ca?.byType}
            colors={colors}
            unit={'€'}
            margin={{
              top: 45, right: 10, bottom: 50, left: 70,
            }}
            legend
            // period={period}
            indexBy='activity'
            keys={['Réel', 'Prévisionnel', 'Période de comparaison']}
            label='CA par famille'
            />
          }
        </div>
      </>
    }
    </div>
  );
}
